export default function cookieConsent() {
  const COOKIE_NAME = 'bpart_cookie_consent_analytics';
  const COOKIE_EXPIRE = 31536000; // 1 year

  return {
    show: null,
    consented: null,

    init() {
      this.$watch('consented', (value) => this.consentChanged(value));
      this.show = !this.isAnalyticsCookieSet();
      this.consented = this.isAnalyticsAllowed();
    },

    consentChanged(consent) {
      switch (consent) {
        case true:
          this.setAnalyticsCookieGranted();
          break;
        case false:
          this.setAnalyticsCookieDenied();
          break;
      }
    },

    handleClick(consent = { consented: false }) {
      this.consented = consent.consented;
      this.show = false;
    },

    setAnalyticsCookieGranted() {
      if (this.isAnalyticsCookieGranted()) return;
      document.cookie = `${COOKIE_NAME}=granted; max-age=${COOKIE_EXPIRE}; path=/`;
      this.$dispatch('gtag-consent', { analytics_storage: 'granted' });
    },

    setAnalyticsCookieDenied() {
      if (this.isAnalyticsCookieDenied()) return;
      document.cookie = `${COOKIE_NAME}=denied; max-age=${COOKIE_EXPIRE}; path=/`;
      this.$dispatch('gtag-consent', { analytics_storage: 'denied' });
    },

    isAnalyticsAllowed() {
      if (this.isAnalyticsCookieDenied()) return false;
      if (this.isAnalyticsCookieGranted()) return true;
      return null;
    },

    isAnalyticsCookieSet() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(COOKIE_NAME));
    },

    isAnalyticsCookieDenied() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(`${COOKIE_NAME}=denied`));
    },

    isAnalyticsCookieGranted() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(`${COOKIE_NAME}=granted`));
    },
  };
}
